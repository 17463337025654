import React, { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react'
import { Center } from '../../parts/bases/Center'
import { VStack } from '../../parts/bases/VStack'
import { FormattedMessage } from 'react-intl'
import { Text } from '../../parts/bases/Text'
import { PinkNeonButton } from '../../parts/buttons/PinkNeonButton'
import { Policy } from './Policy'
import { LocalStorageService } from '../../../services/LocalStorageService'
import { updatedAtKey } from './texts'

type Props = {
  children: ReactNode
}

export const AgreementProvider: React.FC<PropsWithChildren<Props>> = ({ children }) => {
  const [visibleAgreement, setVisibleAgreement] = useState(false)

  useEffect(() => {
    setVisibleAgreement(LocalStorageService.loadPrivacyPolicyAgreementKey() !== updatedAtKey)
  }, [])

  const onClickAgree = useCallback(() => {
    setVisibleAgreement(false)
    LocalStorageService.savePrivacyPolicyAgreementKey(updatedAtKey)
  }, [])

  return visibleAgreement ? <AgreementScreen onClickAgree={onClickAgree} /> : <>{children}</>
}

const AgreementScreen = (props: { onClickAgree: () => void }) => {
  return (
    <VStack className="h-screen w-full items-center justify-center px-5">
      <VStack className="h-[80%] w-full rounded-xl border-2 border-[#00FBEC] bg-black">
        <VStack className="w-full flex-1 overflow-scroll p-6">
          <Text className="mt-6 text-sm leading-6 text-white">
            <Policy />
          </Text>
        </VStack>
        <Center className="py-7">
          <PinkNeonButton onClick={props.onClickAgree}>
            <Text className="font-bold text-white">
              <FormattedMessage id="Agreement.Submit" />
            </Text>
          </PinkNeonButton>
        </Center>
      </VStack>
    </VStack>
  )
}
