import React from 'react'
import { Text } from '../../parts/bases/Text'
import { Terms, PrivacyPolicy } from './texts'

export const Policy = () => {
  return (
    <div className="policy">
      <section className="policy__heading">
        <Text className="text-2xl font-bold text-white">USER TERMS & CONDITIONS OF SERVICE</Text>
      </section>
      <section className="policy__list">
        {Terms.split('\n')
          .filter((t) => t.length > 0)
          .map((text) => {
            return /^[0-9]+\.[0-9]+/.test(text) ? (
              <Text key={text} className="mt-6 text-lg font-bold text-[#00FBEC]">
                {text}
              </Text>
            ) : (
              <p key={text} className="whitespace-pre-wrap">
                {text}
              </p>
            )
          })}
      </section>
      <section className="policy__heading">
        <Text className="text-2xl font-bold text-white">Privacy Policy</Text>
      </section>
      <section className="policy__list">
        {PrivacyPolicy.split('\n')
          .filter((t) => t.length > 0)
          .map((text) => {
            return /^[A-Z]\./.test(text) ? (
              <Text key={text} className="mt-6 text-lg font-bold text-[#00FBEC]">
                {text}
              </Text>
            ) : (
              <p key={text} className="whitespace-pre-wrap">
                {text}
              </p>
            )
          })}
      </section>
    </div>
  )
}
