import React, { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithChildren } from 'react'

type Props = {} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const Clickable: React.FC<PropsWithChildren<Props>> = ({ className, ...props }) => {
  return (
    <button
      className={`relative flex transition-all ${
        props.disabled ? '' : 'touch-manipulation active:opacity-40'
      } ${className ?? ''}`}
      {...props}
    >
      {props.children}
    </button>
  )
}
