import React, { PropsWithChildren, ReactNode } from 'react'
import { Center } from '../bases/Center'
import { Clickable } from '../bases/Clickable'

type Props = {
  children: ReactNode
  onClick: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
  px?: string
  height?: string
  border?: string
}

export const PinkNeonButton: React.FC<PropsWithChildren<Props>> = (props) => {
  const { height = 'h-11', border = 'border-2', px = 'px-10' } = props
  const disabledClass = props.disabled ? 'opacity-50' : 'bg-akiverse-pink bg-opacity-50 shadow-pink'
  return (
    <Clickable
      className={`items-center overflow-hidden rounded-full border-[#FFCBF1] ${disabledClass} ${height} ${border}`}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Center className={`h-full w-full shadow-pink-inset ${px}`}>{props.children}</Center>
    </Clickable>
  )
}
